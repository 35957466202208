import axios from "axios";

export default async function createLead(lead) {
  return axios
    .post("/api/contact", { lead })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}
