export default function KeyCheckPoint({ text, classes = {containerClasses: ""} }) {
  const {containerClasses} = classes
  return (
    <h3 className={"fw-semibold fs-3 " + containerClasses}>
      <div className="row row-cols-2">
        <div className="col-auto pe-0">
          <i className="bi bi-check-lg me-2 fs-c4"></i>
        </div>
        <div className="col d-flex flex-grow-1 align-items-center ps-0">
          <span>{text}</span>
        </div>
      </div>
    </h3>
  );
}
