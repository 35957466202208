import NavbarScroll from "../components/NavbarScroll";
import landingPageConfig from "../landingPageConfig";
import "../styles/landing-page.css";
import { Outlet } from "react-router";
import Footer from "../components/Footer";

export default function Root() {
  const { navBar, footer, root } = landingPageConfig;
  const {classes} = root
  const {containerClass} = classes
  return (
    
      <div className={"container-fluid p-0 " + containerClass}>
        <NavbarScroll {...navBar} />
        <Outlet />
        <Footer {...footer}/>
      </div>
  );
}
