const contactFormConfig = {
  fields: [
    { label: "First", size: 6, type: "text", isRequired: true },
    { label: "Last", size: 6, type: "text", isRequired: true },
    { label: "Phone", size: 6, type: "text", isRequired: false },
    { label: "Industry", size: 6, type: "text", isRequired: false },
    { label: "Email", size: 12, type: "text", isRequired: true },
    { label: "Company", size: 12, type: "text", isRequired: true },
    { label: "Message", size: 12, type: "textarea", isRequired: false },
  ],
  classes: {
  },
  submitButtonText: "Book Your Free Tech Needs Analysis!",
  thankYouText: "Thank you for booking a Tech Needs Analysis! You will hear back from us shortly.",
  header: "We'd Love To Hear From You"
};

export default contactFormConfig