import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

export default function CountdownToDate({
  dateString,
  header,
  buttonText,
  completionMessage,
  link
}) {
  // Date for February 15, 2024, at 4 PM PST
  // Note: PST is UTC-8, but JavaScript Date accounts for local time zone
  // Adjust accordingly if your local time zone is different
  const targetDate = new Date(dateString);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div>{completionMessage}</div>;
    } else {
      // Render a countdown
      return (
        <div className="row bg-dark text-light py-5 mb-7">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h1 className="fw-semibold mb-5">{header}</h1>
                  <div className="row justify-content-center mb-4">
                    <div className="col-auto">
                      <h1 className="fs-c4-5 fw-bold bg-light text-dark rounded-3 px-2">
                        {days}
                      </h1>
                      <h5>days</h5>
                    </div>
                    <div className="col-auto pe-0">
                      <h1 className="fs-c4-5 fw-bold bg-light text-dark rounded-3 px-2">
                        {hours}
                      </h1>
                      <h5>hours</h5>
                    </div>
                    <div className="col-auto px-0">
                      <h1 className="fs-c4-5 fw-bold">:</h1>
                    </div>
                    <div className="col-auto px-0">
                      <h1 className="fs-c4-5 fw-bold bg-light text-dark rounded-3 px-2">
                        {minutes}
                      </h1>
                      <h5>minutes</h5>
                    </div>
                    <div className="col-auto px-0">
                      <h1 className="fs-c4-5 fw-bold">:</h1>
                    </div>
                    <div className="col-auto ps-0">
                      <h1 className="fs-c4-5 fw-bold bg-light text-dark rounded-3 px-2">
                        {seconds}
                      </h1>
                      <h5>seconds</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Link to={link} className="btn btn-light fs-3 fw-semibold">
                        {buttonText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <Countdown date={targetDate} renderer={renderer} />;
}
