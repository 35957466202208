import LottieAnimation from "./Utility/LottieAnimation";
import { useEffect, useState } from "react";

export default function KeyValueCard({ image, header, text, lottie }) {
  const [animationData, setAnimationData] = useState(false);

  useEffect(() => {
    const loadAnimationData = async () => {
      try {
        const module = await import(
          `../utils/lottie-animations/${lottie}.json`
        );
        setAnimationData(module);
      } catch (error) {
        console.error("Error loading component", error);
      }
    };

    loadAnimationData();
  }, [lottie]);

  return (
    <div className="col mb-3">
      <div class="card bg-transparent-white h-100 border-radius-20px">
        <div className="image-container-16-9">
          <img src={image} className="card-img-top ps-2 pt-2 pe-2 border-radius-20px" alt="..." />
        </div>

        <div class="card-body position-relative">
          <h4 class="card-title text-center mt-5 fw-bold">{header}</h4>
          <p class="card-text">{text}</p>{" "}
          <div className="width-100px position-absolute top-0 start-50 translate-middle bg-white rounded-circle p-1">
            {animationData && <LottieAnimation animationData={animationData} />}
          </div>
        </div>
      </div>
    </div>
  );
}
