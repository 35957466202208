import KeyCheckPoint from "./KeyCheckPoint";

export default function Banner2({
  header,
  image,
  subHeader,
  tagLine,
  keyPoints,
  callToAction,
  classes,
}) {
  const { containerClasses } = classes;
  return (
    <div className={"row padding-top-130px min-vh-100 " + containerClasses}>
      <div className="col d-flex flex-column justify-content-start">
        <div className="row mb-3">
          <div className="col-12 col-lg-8">
            <h5 className="mb-3">{subHeader}</h5>
            <h1 className="fw-bold fs-c4-5 mb-3">{header}</h1>
            <h3>{tagLine}</h3>
          </div>
        </div>
        <div className="row row-cols-2">
          <div className="col-12 col-lg-8">
            <div className="row row-cols-1 row-cols-lg-2">
              {keyPoints &&
                keyPoints.map((point, index) => {
                  return (
                    <div className="col">
                      <KeyCheckPoint text={point} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">{callToAction}</div>
        </div>
      </div>
    </div>
  );
}
