const raffleFormConfig = {
  fields: [
    { label: "First", size: 6, type: "text", isRequired: true },
    { label: "Last", size: 6, type: "text", isRequired: true },
    { label: "Phone", size: 6, type: "text", isRequired: false },
    { label: "Industry", size: 6, type: "text", isRequired: false },
    { label: "Email", size: 12, type: "text", isRequired: true },
    { label: "Company", size: 12, type: "text", isRequired: false },
    { label: "Message", size: 12, type: "textarea", isRequired: false },
  ],
  submitButtonText: "Send information",
  thankYouText:
    "Thank you for entering our raffle! You'll be receiving an informational email shortly. We hope you win!",
  header: "Sign up for your chance to win a free Click Funnel!",
  classes: { submitButtonClasses: "btn-outline-light" },
  banner: {
    header: "Claim Your Chance At A Free Click Funnel!",
    subHeader: "A new winner is chosen every month, this time it could be you",
    keyPoints: [
      "Improved Conversion Rates",
      "Effective Lead Capture",
      "Enhanced Customer Journey",
      "Increased Return On Investment",
    ],
    callToAction: "Enter your information below",
    tagLine: "Convert clicks to sales with our click funnel solutions",
    classes: {
      containerClasses: "justify-content-center ",
    },
  },
};

export default raffleFormConfig;
