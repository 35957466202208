import NavLinkScroll from "./NavLinkScroll";
import "../styles/navbar.css";
import { Link } from "react-router-dom";
import ContactButton from "./ContactButton";

export default function NavbarScroll({
  containerId,
  links,
  logo,
  classes,
  logoText,
}) {
  const {
    logoClasses,
    containerClasses,
    navClasses,
    contactButtonClasses,
    collapseClasses,
  } = classes;
  return (
    <nav
      className={
        "navbar navbar-expand-lg fixed-top bg-dark navbar-container " +
        navClasses
      }
      data-bs-theme="dark"
    >
      <div className={"container-fluid " + containerClasses}>
        <Link to={"/"} className="navbar-brand" href="#">
          {logo ? (
            <img className={logoClasses} src={logo}></img>
          ) : (
            <h1 className={"fw-bold " + logoClasses}>{logoText}</h1>
          )}
        </Link>

        <ContactButton classes={contactButtonClasses} />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={"collapse navbar-collapse " + collapseClasses}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            {links.map((item, index) => {
              const { displayName, link, isScroll } = item;

              return isScroll ? (
                <li className="nav-item">
                  <NavLinkScroll
                    link={link}
                    containerId={containerId}
                    classes={"text-end"}
                  >
                    <span className="d-inline-block mx-auto">
                      {displayName}
                    </span>
                  </NavLinkScroll>
                </li>
              ) : (
                // <li className="nav-item">
                //   <Link
                //     to={link}
                //     classes={"nav-link fs-5 text-end"}
                //   >
                //     <span className="d-inline-block mx-auto">
                //       {displayName}
                //     </span>
                //   </Link>
                // </li>
                <li className="nav-item">
                  <Link
                    to={link}
                    class={"d-block text-end"}
                  >
                    <span className="d-inline-block mx-auto nav-link fs-5">
                      {displayName}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* </div> */}
      </div>
    </nav>
  );
}
