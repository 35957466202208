import React from "react";
import { Link } from "react-router-dom";

export default function CallToAction({
  header,
  buttonText,
  link,
  subHeader
}) {
  return (
    <div className="row bg-dark text-light py-5 mb-7">
      <div className="col">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7 text-center">
              <h5>{subHeader}</h5>
              <h1 className="fw-bold mb-4 fs-c3-5">{header}</h1>
              <Link to={link} className="btn btn-light fs-3 fw-semibold">
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
