import Banner from "../components/Banner";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
// import landingPageConfig from "../landingPageConfig";
import UniqueValue from "../components/UniqueValue";
import FAQ from "../components/FAQ";
import Portfolio from "../components/Portfolio";
import Statistics from "../components/Statistics";
import Projects from "../components/Projects";
import OurProcess from "../components/OurProcess";
import UniqueValue2 from "../components/UniqueValue2";
import CountDownToDate from '../components/CountDown'
import CallToAction from "../components/CallToAction";

export default function Index({
  sections,
  banner,
  services,
  testimonials,
  uniqueValue,
  faq,
  portfolio,
  statistics,
  projects,
  ourProcess,
  uniqueValue2,
  countDownToDate,
  callToAction
}) {
  const {
    bannerIsDisplayed,
    statisticsIsDisplayed,
    uniqueValueIsDisplayed,
    servicesIsDisplayed,
    portfolioIsDisplayed,
    ourProcessIsDisplayed,
    testimonialsIsDisplayed,
    projectsIsDisplayed,
    faqIsDisplayed,
    uniqueValue2IsDisplayed,
    countDownToDateIsDisplayed,
    callToActionIsDisplayed
  } = sections;
  return (
    <>
      {bannerIsDisplayed && <Banner {...banner} />}
      {statisticsIsDisplayed && <Statistics {...statistics} />}
      {uniqueValue2IsDisplayed && <UniqueValue2 {...uniqueValue2}/>}
      {countDownToDateIsDisplayed && <CountDownToDate {...countDownToDate}/>}
      {uniqueValueIsDisplayed && <UniqueValue {...uniqueValue} />}
      {servicesIsDisplayed &&<Services {...services} />}
      {portfolioIsDisplayed && <Portfolio {...portfolio} />}
      {callToActionIsDisplayed && <CallToAction {...callToAction}/>}
      {ourProcessIsDisplayed && <OurProcess {...ourProcess} />}
      {testimonialsIsDisplayed && <Testimonials {...testimonials} />}
      {projectsIsDisplayed && <Projects {...projects} />}
      {faqIsDisplayed && <FAQ {...faq} />}
    </>
  );
}
