import { Outlet } from "react-router-dom";
import Banner2 from "../components/Banner2";
import raffleFormConfig from "../raffleFormConfig";
import "../styles/raffle.css";

export default function Raffle({ header }) {
  return (
    <div className="container-fluid min-vh-100 raffle-container text-light ">
      <div className="row bg-dark-06 pb-5">
        <div className="col">
          <div className="container">

        <Banner2 {...raffleFormConfig.banner} />
        <div className="row ">
          <div className="col-12 col-lg-8">
            <Outlet />
          </div>
        </div>
          </div>

        </div>
      </div>
    </div>
  );
}
