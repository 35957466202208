import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";
import "./styles/custom.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import Root from "./routes/Root";
import Index from "./routes/Index";
import Raffle from "./routes/Raffle";
import Contact from "./routes/Contact";
import ContactForm, { action as contactAction } from "./routes/ContactForm";
import ThankYou from "./routes/ThankYou";

import contactFormConfig from "./contactFormConfig";
import landingPageConfig from "./landingPageConfig";
import raffleFormConfig from "./raffleFormConfig";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Index {...landingPageConfig} />,
      },
      {
        path: "/contact",
        element: <Contact />,
        children: [
          {
            index: true,
            element: <ContactForm contactFormConfig={contactFormConfig} />,
            action: contactAction,
          },
          {
            path: "thankyou",
            element: <ThankYou />,
          },
        ],
      },
      {
        path: "/raffle",
        element: <Raffle />,
        children: [
          {
            index: true,
            element: <ContactForm contactFormConfig={raffleFormConfig}/>,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

//make main.jsx the entry point in package.json
