import { Form } from "react-router-dom";
// import contactFormConfig from "../contactFormConfig";
import ContactFields from "../components/contact-form/ContactFields";
import { redirect } from "react-router";
import createLead from "../http/contact"

export async function action({ request }) {
  const formData = await request.formData();
  const lead = Object.fromEntries(formData);
  const response = await createLead(lead)
  return redirect("thankyou");
}

export default function ContactForm({contactFormConfig}) {

  const {classes} = contactFormConfig
  const {submitButtonClasses} = classes

  return (
    <Form method="post">
      <div className="row">
        <div className="col">
          <h1 className="fw-bold mb-4">{contactFormConfig.header}</h1>
        </div>
      </div>
      <ContactFields {...contactFormConfig} />{" "}
      <div className="row">
        <div className="col">
          <button
            className={
              "btn fw-semibold border border-3 " + submitButtonClasses
            }
            type="submit"
          >
            {contactFormConfig.submitButtonText}
          </button>
        </div>
      </div>
    </Form>
  );
}
