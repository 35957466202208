const navbarPresets = {
  circlePopOut: function({logo, container, contactButton, nav, collapse}) {
    return {
      logoClasses: "circle-popout-logo position-absolute " + logo,
      containerClasses: "position-relative " + container,
      navClasses: nav,
      contactButtonClasses: "ms-auto " + contactButton,
      collapseClasses: "flex-grow-0 " + collapse
    }
  }
}

export default navbarPresets