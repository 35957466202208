import { v4 as uuidv4 } from "uuid";
import KeyCheckPoint from "./KeyCheckPoint";
import KeyValueCard from "./KeyValueCard";

export default function UniqueValue2({
  header,
  subHeader,
  text,
  keyPoints,
  cardPoints,
  header2
}) {
  return (
    <div className="container mb-7">
      <div className="row">
        <div className="col">
          <h1 className="fw-bold fs-c4-5 text-start">{header}</h1>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-lg-8">
          <h2 className="fw-semibold fs-1">{subHeader}</h2>
          <p>{text}</p>
        </div>
        <div className="col-12 col-lg-4 mt-3">
          {keyPoints.map((point, index) => {
            return (
              <KeyCheckPoint text={point} key={"key-check-point" + uuidv4()} />
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1 className="text-center mb-4 fw-semibold fs-1">{header2}</h1>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 justify-content-center">
        {cardPoints.map((card, index) => {
          return <KeyValueCard {...card} key={"key-value-card" + uuidv4()} />;
        })}
      </div>
    </div>
  );
}
