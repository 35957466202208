import { Outlet } from "react-router-dom";

export default function Contact() {
  return (
    <div className="container min-vh-100 padding-top-130px">
      <div className="row">
        <div className="col-12 col-lg-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
